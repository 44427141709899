@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

/* MASTER STYLES */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
}

a {
  text-decoration: none;
  color: #000;
}

ul {
  list-style: none;
}

p {
  margin: 10px 0;
}

::-webkit-scrollbar {
  display: none;
}

.container {
  width: 100%;
  max-width: 960px;
  margin: auto;
  padding: 0 30px;
}

/* NAVBAR */
.logo {
  font-size: x-large;
  text-transform: uppercase;
  color: #fff;
}

.logo-img {
  width: 100px;
  vertical-align: middle;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  background-color: #056839;
  padding: 10px 20px;
  text-transform: uppercase;
}

.navbar ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar li {
  margin-left: 20px;
}

.navbar ul li a {
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
}

.navbar ul li a:hover {
  color: #333;
  border-bottom: 1px solid #333;
}

/* BUTTONS */
.btn {
  display: inline-block;
  background: #056839;
  color: #fff;
  border: none;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}

.btn-reverse {
  background: #fff;
  color: #000;
  border-color: #000;
}

.btn:focus {
  outline: none;
}

.btn:active {
  transform: scale(0.98);
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-delete {
  background: darkred;
  color: #fff;
  border-color: darkred;
  font-size: 13px;
  padding: 5px 10px;
}

/* CONTENT */
.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.page-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.sponsor-img {
  max-width: 20%;
  height: auto;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}

@media (max-width: 700px) {
  .sponsor-img {
    max-width: 33%;
  }
}

.signatures {
  max-width: 60%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.table {
  width: 100%;
  text-align: left;
}

.table td, th {
  vertical-align: middle;
  padding: 15px;
  border-bottom: 1px solid #ddd;
}

.player-detail-header {
  width: 100%;
  margin-bottom: 10px;
}

.player-detail-img-box {
  height: 122px;
  width: 122px;
  border: 1px #ffffff00 none;
  display: inline;
  float: left;
  margin-right: 30px;
}

.player-detail-img {
  width: 120px;
  height: 120px;
  float: left;
  border-radius: 5px;
}

.player-detail-title-box {
  display: inline;
  border: 1px #ffffff00 solid;
  font-size: small;
  width: 80%;
}

@media (max-width: 700px) {
  .player-detail-img {
    width: 90px;
    height: 90px;
  }

  .player-detail-img-box {
    height: 92px;
    width: 92px;
    border: 1px #ffffff solid;
  }

  .player-detail-title-box {
    font-size: x-small;
  }

}

.player-list-img {
  width: 70px;
  float: right;
  margin: 10px, 10px, 10px, 25px;
  border-radius: 5px;
}

.player-list-name {
  margin: auto;
  vertical-align: auto;
  text-align: left;
}

.player-list-handicap {
  margin: auto;
  vertical-align: auto;
  text-align: left;
}

/* AUTH */
.auth-container {
  max-width: 600px;
  margin: auto;
}

.auth-container h1 {
  text-align: center;
}

.auth-container .pageHeader {
  display: block;
}

.auth-container fieldset {
  padding: 15px;
  border-radius: 5px;
}

.auth-container fieldset label {
  margin-right: 10px;
}

/* FORMS */
.form-control {
  margin: 20px 0;
}

.form-control label {
  display: block;
}

.form-control input,
.form-control textarea {
  width: 100%;
  height: 40px;
  margin: 5px;
  padding: 3px 7px;
  font-size: 17px;
}

.form-control textarea {
  height: 200px;
}

.form-control-check {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-control-check label {
  flex: 1;
}

.form-control-check input {
  flex: 2;
  height: 20px;
}

/* POSTS */
.posts-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  justify-content: space-between;
  align-items: flex-start;
}

.posts-list li {
  padding: 20px;
  border: 1px rgb(240, 240, 240) solid;
  border-radius: 5px;
}

.posts-list li:hover {
  background-color: rgb(245, 245, 245);
}

.posts-list li h3 {
  margin-bottom: 5px;
}

/* SPLASH */
.splash-img {
  position: relative;
  display: flex;
  margin-top: -30px;
  padding: 0;
  width: 100%;
  height: 500px;
  object-fit: cover;
}

.splash-content {
  height: auto;
  position: absolute;
  display: block;
  top: 250px;
  left: 10%;
  transform: translate(-0%, -50%);
  color: white;
  text-align: left;
  background-color: rgb(17, 85, 46);
  opacity: 0.8;
  padding: 20px;
}

@media (max-width: 800px) {
  .splash-content {
    font-size: smaller;
    left: 5%;
    max-width: 90%;
  }
}

/* COLLAPSE */
.Collapsible {
  background-color: rgb(255, 255, 255);
  border: 1px #e3e3e3 solid;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
}

.Collapsible__trigger {
  margin-left: auto;
  margin-right: auto;
  font-size: large;
  font-weight: bolder;
}

.Collapsible__trigger:hover {
  cursor: pointer;
}

/* MISC AND MODIFIERS */
.content-left {
  width: 50%;
  float: left;
  display: inline;
}

.content-right {
  width: 50%;
  float: left;
  display: inline;
}

.center {
  text-align: center;
}

.content-left {
  text-align: left;
}

.bg-grey {
  background-color: #f0f0f0;
}

.bg-white {
  background-color: #ffffff;
}

.error {
  color: darkred;
}
